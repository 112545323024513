<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col class="px-2" sm v-if="$can('export')">
        <div>
          <button
            class="btn btn-success m-2"
            type="button"
            @click="exportTable"
          >
            Export
          </button>
          <!-- <router-link :to="{ name: 'Release' }" class="btn btn-primary m-2"> Release Dana</router-link> -->
        </div>
      </b-col>
      <b-col
        class="d-flex align-items-start justify-content-end px-2"
        sm
        v-if="$can('multiple_release')"
      >
        <button
          class="btn btn-purple m-2"
          type="button"
          @click="multipleAction('retry')"
          v-show="isRetry"
        >
          Retry Multiple
        </button>
        <button
          class="btn btn-success m-2"
          type="button"
          @click="multipleAction('approve')"
          v-show="isApprove"
        >
          Approve Multiple
        </button>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Name, Email, Phone, Bank Name"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name,  Email,, Phone, Bank Name ..."
                @keyup.enter="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <month-range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                @picker="doDateFilter"
              ></month-range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
      pagination-path="pagination"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="checkbox-slot" slot-scope="prop">
        <input
          type="checkbox"
          @click="onChangeChecked(prop.rowData)"
          :value="prop.rowData"
          v-model="selected"
          v-show="prop.rowData.can_approve || prop.rowData.can_retry"
        />
      </template>
      <template slot="name-slot" slot-scope="prop">
        <router-link :to="handleRedirectUser(prop.rowData)" target="_blank">{{
          prop.rowData.name
        }}</router-link>
      </template>
      <template slot="organization-slot" slot-scope="prop">
        <span>{{
          prop.rowData.organization_name === null
            ? "-"
            : prop.rowData.organization_name
        }}</span>
      </template>
      <template slot="partner-slot" slot-scope="prop">
        <span>{{
          prop.rowData.partner_name === null ? "-" : prop.rowData.partner_name
        }}</span>
      </template>
      <template slot="transaction_id-slot" slot-scope="prop">
        <span>{{ prop.rowData.transaction_id }}</span>
      </template>
      <template slot="amount-slot" slot-scope="prop">
        <!-- <span v-if="prop.rowData.bank_account.bank_id === 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id === 'SYNIIDJ1'">
            {{ (Number(prop.rowData.amount)) | currency }}
          </span>
          <span v-else-if="prop.rowData.listing == false && prop.rowData.bank_account.bank_id !== 'BNINIDJAXXX' || prop.rowData.bank_account.bank_id !== 'SYNIIDJ1'">
            {{ (Number(Number(prop.rowData.amount) - 2500)) | currency}}
          </span> -->
        <span>
          {{ Number(prop.rowData.amount) | currency }}
        </span>
      </template>
      <template slot="bank_name-slot" slot-scope="prop">
        <span
          >{{ prop.rowData.owner }} - {{ prop.rowData.bank_name }} -
          {{ prop.rowData.account_number }}</span
        >
      </template>
      <template slot="updated_at-slot" slot-scope="prop">
        <span>{{
          prop.rowData.is_send && prop.rowData.status === 1
            ? prop.rowData.updated_at
            : "-"
        }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button
            type="button"
            class="btn btn-warning mb-3 mx-3"
            @click="onAction('check-item', prop.rowData)"
          >
            Check
          </button>
          <button
            v-show="prop.rowData.status == 3"
            type="button"
            class="btn btn-danger mb-3 mx-3"
            @click="
              onAction('log-response', prop.rowData, (logResponse = true))
            "
          >
            Log
          </button>
          <button
            class="btn btn-info mb-3 mx-3"
            @click="onAction('detail-item', prop.rowData, prop.rowIndex)"
          >
            Detail
          </button>
          <button
            v-if="$can('approve_withdraw')"
            v-show="prop.rowData.can_approve"
            class="btn btn-success mb-3 mx-3"
            @click="onAction('approve', prop.rowData, prop.rowIndex)"
          >
            Approve
          </button>
          <button
            v-if="$can('approve_withdraw')"
            v-show="prop.rowData.can_retry"
            class="btn btn-purple mb-3 mx-3"
            @click="onAction('retry', prop.rowData, prop.rowIndex)"
          >
            Retry
          </button>
          <!-- <button  v-if="$can('approve_withdraw')" v-show="prop.rowData.status != 2 && prop.rowData.status != 1 && prop.rowData.listing === false" class="btn btn-success" @click="onAction('approve', prop.rowData, prop.rowIndex)">Approve</button>&nbsp; -->
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="pagination-custom" v-if="!isLoadingTable">
        <div class="pagination-info">
          <p v-if="pagination.total > 0">
            {{
              `Displaying ${pagination.from} to ${pagination.to} of ${pagination.total} items`
            }}
          </p>
          <p v-else>No relevant data</p>
        </div>
        <div class="pagination-button">
          <button
            :disabled="
              pagination.current_page === 1 || !pagination.first_page_url
            "
            @click="doMovePage('first')"
          >
            <i
              v-if="pagination.current_page === 1 || !pagination.first_page_url"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button
            :disabled="pagination.current_page === 1"
            @click="doMovePage('prev')"
          >
            <i
              v-if="pagination.current_page === 1"
              class="fa fa-angle-left disabled"
            ></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div
            v-for="page in visiblePages"
            :key="page"
            :class="{ active: page === currentPage }"
            @click="doMovePage(page)"
          >
            {{ page }}
          </div>
          <button
            :disabled="
              currentPage === pagination.last_page || !pagination.next_page_url
            "
            @click="doMovePage('next')"
          >
            <i
              v-if="
                currentPage === pagination.last_page ||
                  !pagination.next_page_url
              "
              class="fa fa-angle-right disabled"
            ></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
          <button
            :disabled="
              currentPage === pagination.last_page || !pagination.last_page_url
            "
            @click="doMovePage('last')"
          >
            <i
              v-if="
                currentPage === pagination.last_page ||
                  !pagination.last_page_url
              "
              class="fa fa-angle-double-right disabled"
            ></i>
            <i v-else class="fa fa-angle-double-right"></i>
          </button>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      title="Log Response"
      v-model="logResponse"
      @oke="logResponse = false"
    >
      <div style="text-align: center; width: 100%">
        {{ this.errorResponse }}
      </div>
    </b-modal>
    <b-modal title="Check Account" size="lg" v-model="largeModal" centered>
      <div v-if="this.isLoadingModal">
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="210px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 5"
              :key="index"
              width="250px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <b-skeleton width="180px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 4"
              :key="index"
              width="230px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-skeleton width="160px"></b-skeleton>
            <hr />
            <b-skeleton
              v-for="index in 3"
              :key="index"
              width="270px"
              style="margin: 0 0 10px 40px"
            ></b-skeleton>
            <div style="margin-top: 32px">
              <b-skeleton width="500px"></b-skeleton>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="typeof this.user.user !== 'undefined'">
        <b-row class="mb-4">
          <b-col>
            <h6>Profile User {{ this.user.user.name }}</h6>
            <hr />
            <ul style="font-size: 12pt">
              <li>Nama : {{ this.user.user.name }}</li>
              <li>Email : {{ this.user.user.email }}</li>
              <li>No Telepon : {{ this.user.user.phone }}</li>
              <li>Alamat : {{ this.user.user.address }}</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h6>
              Bank Account {{ this.user.bank.account_name.toUpperCase() }}
            </h6>
            <hr />
            <ul style="font-size: 12pt">
              <li>Owner : {{ this.user.bank.account_name.toUpperCase() }}</li>
              <li>Account Number : {{ this.user.bank.account_number }}</li>
              <li>Bank Name : {{ this.user.bank.name }}</li>
              <li>Branch : {{ this.user.bank.branch }}</li>
            </ul>
          </b-col>
        </b-row>
        <b-row class="mb-4">
          <b-col>
            <h6>Status Disbursement</h6>
            <hr />
            <ul style="font-size: 12pt">
              <li>
                Status Transaksi : {{ this.user.disbursement.status_text }}
              </li>
              <li>Response Message : {{ this.user.disbursement.message }}</li>
              <li>
                Tanggal Pengecekan : {{ this.user.disbursement.check_date }}
              </li>
            </ul>
            <div>
              <h5 :style="{ color: user.bank.status ? 'green' : 'red' }">
                {{ this.user.bank.message }}
              </h5>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import accounting from "accounting";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import _ from "lodash";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      page: 1,
      currentPage: 1,
      limit: 10,
      data: [],
      pagination: {},
      visiblePages: [],
      startDate: "",
      endDate: "",
      selected: [],
      user: [],
      isLoading: false,
      isLoadingTable: false,
      isLoadingModal: false,
      largeModal: false,
      filterText: "",
      message: "",
      dateDetail: {
        start_from: "",
        end_from: ""
      },
      logResponse: false,
      errorResponse: "",
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `wallet`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sortOrder: "created_at|asc",
      moreParams: {},
      fields: [
        {
          name: "checkbox-slot", // <----
          titleClass: "center aligned",
          dataClass: "center aligned",
          title: "",
          width: "5%"
        },
        {
          name: "created_at",
          sortField: "created_at",
          title: "Request Date"
        },
        {
          name: "name-slot",
          sortField: "name",
          title: "Nama Akun Profile"
        },
        {
          name: "organization-slot",
          title: "Organization"
        },
        {
          name: "partner-slot",
          title: "Partner"
        },
        {
          name: "transaction_id-slot",
          sortField: "transaction_id",
          title: "Invoice"
        },
        {
          name: "bank_name-slot",
          sortField: "bank_name",
          title: "Tujuan Pencairan Dana"
        },
        {
          name: "amount-slot",
          sortField: "amount",
          title: "Nilai Rupiah"
        },
        {
          name: "status",
          sortField: "status",
          formatter: this.statusField
        },
        {
          name: "updated_at-slot",
          sortField: "updated_at-slot",
          title: "Release Date"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    },
    pagination() {
      this.updateVisiblePages();
    },
    currentPage() {
      this.updateVisiblePages();
    },
    largeModal(newVal) {
      if (!newVal) this.user = [];
    }
  },
  computed: {
    isRetry() {
      const values = this.selected;
      const valuesFilter = values.filter(itm => itm);

      if (valuesFilter.length > 0) return values.every(itm => itm?.can_retry);

      return false;
    },
    isApprove() {
      const values = this.selected;
      const valuesFilter = values.filter(itm => itm);

      if (valuesFilter.length > 0) return values.every(itm => itm?.can_approve);

      return false;
    }
  },
  methods: {
    handleRedirectUser(data) {
      return { name: "Detail End User", params: { id: btoa(data.user_id) } };
    },
    updateFilter() {
      const historyFilter = localStorage.getItem("history_filter_wallet");
      const payload = {
        page: this.page,
        filterText: this.filterText,
        startDate: this.startDate,
        endDate: this.endDate,
        sortOrder: this.sortOrder,
        moreParams: this.moreParams
      };

      if (historyFilter && historyFilter !== JSON.stringify(payload)) {
        const newFilter = JSON.parse(historyFilter);
        this.page = newFilter.page;
        this.filterText = newFilter.filterText;
        this.startDate = newFilter.startDate;
        this.endDate = newFilter.endDate;
        this.sortOrder = newFilter.sortOrder;
        this.moreParams = newFilter.moreParams;
        localStorage.removeItem("history_filter_wallet");
      }

      this.onFetch();
    },
    onFetch() {
      const newParams = Object.entries(this.moreParams)
        .filter(([value]) => value !== "")
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.isLoadingTable = true;

      this.$http
        .get(
          `wallet?sort=${encodeURIComponent(this.sortOrder)}&page=${
            this.page
          }&per_page=${this.limit}&${newParams}`
        )
        .then(response => {
          const newPagination = Object.fromEntries(
            Object.entries(response.data).filter(([key]) => key !== "data")
          );

          this.data = response.data.data;
          this.currentPage = response.data.current_page;
          this.pagination = newPagination;
          this.isLoadingTable = false;
          this.isLoading = false;

          if (this.page !== response.data.current_page)
            this.page = response.data.current_page;
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sortOrder !== newSort) {
          this.sortOrder = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    updateVisiblePages() {
      const range = 5; // Jumlah halaman yang akan ditampilkan
      const halfRange = Math.floor(range / 2);
      const start = Math.max(1, this.currentPage - halfRange);
      const end = Math.min(this.pagination.last_page, start + range - 1);

      this.visiblePages = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      );
    },
    onChangeChecked(val) {
      const id = val?.id;
      const arr = this.selected.some(itm => itm?.id == id);

      if (!arr) {
        this.selected.push(val);
      } else {
        const indexId = this.selected.findIndex(itm => itm?.id == id);
        if (indexId > -1) this.selected.splice(indexId, 1);
      }
    },
    multipleAction(type) {
      this.$swal
        .fire({
          title: "Apakah kamu yakin?",
          text: `Transaksi yang sudah di proses tidak dapat dibatalkan. Apakah anda yakin ingin memproses ${this.selected.length} transaksi tersebut ?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No"
        })
        .then(result => {
          if (result.value) {
            const values = this.selected.filter(itm => itm);
            const user = values.map(itm => itm?.id);

            this.isLoading = true;
            this.$http
              .post(
                `wallet/${
                  type == "retry" ? "multiple-retry" : "multiple-approve"
                }`,
                { user }
              )
              .then(() => {
                this.isLoading = false;
                this.$swal
                  .fire("Success!", "Dana Berhasil dikirim!", "success")
                  .then(() => {
                    this.$refs.vuetable.refresh();
                    this.selected = [];
                    this.isLoading = true;
                    this.onFetch();
                  });
              })
              .catch(error => {
                if (error.response) {
                  this.isLoading = false;
                  this.errors.code = error.response.status;
                  this.errors.message = error.response.data.meta.message;
                  this.errors.status = error.response.data.meta.code;
                  if (this.errors.status == 400) {
                    this.$swal
                      .fire("Failed", this.errors.message, "error")
                      .then(() => {
                        this.$refs.vuetable.refresh();
                        this.selected = [];
                        this.isLoading = true;
                        this.onFetch();
                      });
                  } else if (this.errors.status == 404) {
                    this.$swal
                      .fire("Failed", this.errors.message, "error")
                      .then(() => {
                        location.reload();
                      });
                  }
                }
              });
          }
        });
    },
    statusField(value) {
      return value == 0
        ? "Sedang Diproses"
        : value == 1
        ? "Berhasil"
        : value == 2
        ? "Ditolak"
        : "Gagal";
    },
    goldField(value) {
      return value + " Gram";
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.$emit("filter-reset");
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD");
        startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      }
      this.$http
        .get(`withdraw/wallet/` + startDate + `/` + endDate)
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    formatNumber(value) {
      return "Rp." + accounting.formatNumber(value, 2);
    },
    number(value) {
      return value;
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      console.log(paginationData);
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async onAction(action, data) {
      if (action == "detail-item") {
        const payload = {
          page: this.page,
          filterText: this.filterText,
          startDate: this.startDate,
          endDate: this.endDate,
          sortOrder: this.sortOrder,
          moreParams: this.moreParams
        };

        localStorage.setItem("history_filter_wallet", JSON.stringify(payload));
        this.$router.push({
          name: "Detail Withdraw Celenganku",
          params: { id: data.hashed_id }
        });
      } else if (action == "check-item") {
        this.largeModal = true;
        this.isLoadingModal = true;
        await this.$http
          .post(`wallet/check-transaction`, {
            withdraw_id: data.hashed_id
          })
          .then(response => {
            this.isLoadingModal = false;
            this.user = response.data.data;
          })
          .catch(error => {
            if (error.response) {
              this.largeModal = false;
              this.isLoading = false;
              this.isLoadingModal = false;
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              if (this.errors.status == 400 || this.errors.status == 404) {
                this.$swal
                  .fire("Failed", this.errors.message, "error")
                  .then(() => {});
              }
            }
          });
      } else if (action === "log-response") {
        if (this.isJsonString(data.response)) {
          this.errorResponse = JSON.parse(data.response);
        } else {
          this.errorResponse = data.response;
        }
      } else {
        this.isLoading = true;

        // var timeout = setTimeout(() => {
        //   this.isLoading = false;
        //   this.$swal.fire(
        //       'Error!',
        //       'Proses disbursement Timeout - mohon di check mutasi rekening sebelum proses kembali!',
        //       'error'
        //     ).then(() => {
        //       this.$refs.vuetable.refresh();
        //       this.selected = [];
        //       this.onFetch();
        //     })
        // },30000)

        this.$http
          .post(
            `wallet/${action == "approve" ? "approve" : "retry-approve"}`,
            {
              user: data.hashed_id
            },
            { timeout: 30000 }
          )
          .then(res => {
            this.isLoading = false;
            if (res == "ECONNABORTED") {
              this.$swal
                .fire(
                  "Error!",
                  "Proses disbursement Timeout - mohon di check mutasi rekening sebelum proses kembali!",
                  "error"
                )
                .then(() => {
                  this.$refs.vuetable.refresh();
                  this.selected = [];
                  this.onFetch();
                });
            } else {
              this.$swal
                .fire(
                  "Success!",
                  "Dana Berhasil dikirim! untuk no invoice " +
                    data.transaction_id,
                  "success"
                )
                .then(() => {
                  event.preventDefault();
                  this.$refs.vuetable.refresh();
                  this.selected = [];
                  this.isLoading = true;
                  // clearTimeout(timeout)
                  this.onFetch();
                });
            }
          })
          .catch(error => {
            if (error.response) {
              this.isLoading = false;
              this.errors.code = error.response.status;
              this.errors.message = error.response.data.meta.message;
              this.errors.status = error.response.data.meta.code;
              // clearTimeout(timeout)
              if (this.errors.status >= 400) {
                this.$swal
                  .fire("Failed", this.errors.message, "error")
                  .then(() => {
                    event.preventDefault();
                    this.$refs.vuetable.refresh();
                    this.selected = [];
                    this.isLoading = true;
                    this.onFetch();
                  });
              } else if (this.errors.status == 404) {
                this.$swal
                  .fire("Failed", this.errors.message, "error")
                  .then(() => {
                    location.reload();
                  });
              }
            }
          });
      }
    },
    onFilterSet(filterText) {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      this.page = 1;
      this.moreParams = {
        ...newParams,
        filter: encodeURIComponent(filterText)
      };
      this.onFetch();
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "tart_from" && key !== "end_to"
        )
      );
      this.page = 1;
      this.moreParams = {
        ...newParams,
        start_from: this.startDate,
        end_to: this.endDate
      };
      this.onFetch();
    },
    onFilterReset() {
      this.page = 1;
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
      this.onFetch();
    },
    onDateFilterReset() {
      this.page = 1;
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "start_from" && key !== "end_to"
        )
      );
      this.onFetch();
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "last") {
        this.page = this.pagination.last_page;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else if (eventData === "next") {
        this.page++;
        this.onFetch();
      } else {
        this.page = eventData;
        this.onFetch();
      }
    }
  },
  mounted() {
    this.updateFilter();
    this.updateVisiblePages();
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));
  }
};
</script>
<style>
.custom-action {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
}

.btn-purple {
  color: #fff;
  background-color: #4933f3;
  border-color: #4933f3;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

footer.modal-footer {
  display: none;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Pagination Custom */
.pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-button {
  display: flex;
}

.pagination-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px;
}

.pagination-button button:last-child {
  padding: 13px 30px 13px 16px;
  border-radius: 0 4px 4px 0;
  border-width: 1px 1px 1px 0;
}

.pagination-button button:nth-child(2),
.pagination-button button:nth-last-child(2) {
  font-size: 1.3em;
  border-width: 1px 1px 1px 0;
}

.pagination-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-button button i.disabled {
  opacity: 0.6;
}

.pagination-button div {
  width: 32px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-width: 1px 1px 1px 0;
}
.pagination-button div.active {
  background-color: #eee;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
